import React from "react"

import "../styles/index.scss"
import "../styles/secondary_pages.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"


import { Link, graphql } from "gatsby"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'


import BannerMobile from "../images/editorial/about-us-banner-mobile.jpg"
import Banner from "../images/editorial/about-us-banner.jpg"

import ImgAboutCollage from "../images/editorial/about-us-collage.jpg"
import ImgCity from "../images/svg/MSM-city.svg"

import Portrait1 from "../images/portraits/Charles-Caporale.jpg";
import Portrait2 from "../images/portraits/Curt-Zimmer.jpg";
import Portrait3 from "../images/portraits/Jessica-York.jpg";
import Portrait4 from "../images/portraits/Matt-Haberman.jpg";
import Portrait5 from "../images/portraits/Pam-Pinkstaff.jpg";
import Portrait6 from "../images/portraits/Nic-Beas.jpg";
import Portrait7 from "../images/portraits/Christine-Doane.jpg";
import Portrait8 from "../images/portraits/Jeffery-Brady.jpg";
import Portrait9 from "../images/portraits/Mark-Quenan.jpg";
import Portrait10 from "../images/portraits/Michele-Godges.jpg";
import Portrait11 from "../images/portraits/placeholder-1.png";

const RevCycle = () => (

  <React.Fragment>
    <SEO title="About Us" description="MSM is your indispensable partner for better healthcare." />

    <Layout>

      <section id="sct-uvp" className="container ctr-headline secondary-headline">
        <div className="frame-image">
          <article>

            <h2>MSM is your<br />
              <span>indispensable partner</span><br />
              for better healthcare.
      </h2>

          </article>

          <picture>
            <source srcSet={BannerMobile} media="(max-width:600px)" />
            <source srcSet={Banner} />
            <img src={Banner} alt="" className="lazyload" />
          </picture>


        </div>
      </section>

      <section className="container ctr-potential ctr-collage" id="sct-collage" style={{ backgroundColor: "#389ebf" }}>
        <div className="content">

          <div className="ctr-description animated ">
            <h2>Medical Specialties Managers emboldens medical group practices to reach their fullest potential.</h2>
            <p>Founded in 1985, we deliver meticulously personalized services in practice management, revenue cycle management, and technology—giving physician groups the seasoned strategy and robust support that enable them to scale.</p>

            <p>We act as a transparent extension of our clients’ practices, generating measurable outcomes with superlative people and cutting-edge technology. </p>
          </div>


          <div className="svg">

            <img src={ImgAboutCollage} alt="" className="lazyload" />
          </div>

          <div className="spacer"></div>
        </div>
      </section>

      <section className="container ctrinfoGraphicContainer ctr-single-column" >

        <div className="content">
          <article className="ctrInfoGraphic ctrInfoGraphic-description">
            <div className="article-content">
              <h4>
                Whom we serve
      </h4>
              <p>MSM works with physician groups, whether independent or part of a larger healthcare system. With an expansive breadth of specialties, our team provides every solution a practice needs for success. </p>
            </div>
            <div className="article-content">
              <h4>
                How we add value
      </h4>
              <p>We improve revenue, decrease expenses, and simplify the day-to-day challenges of running a medical practice. MSM helps claim a space for your company in the challenging healthcare industry, securing you a business that’s financially and operationally sound. </p>
            </div>
            <div className="article-content">
              <h4>
                What drives us
      </h4>
              <p>We are driven by our ability to fuel smooth partnerships—and watch as the results enrich our clients, increase their footprints, and make their practices more meaningful and impactful. </p>
            </div>
          </article>

          <article className="ctrInfoGraphic">

            <div className="svg svg-vert">
              <img src={ImgCity} />
            </div>

          </article>

        </div>

      </section>

      <section className="container ctr-roster" style={{ backgroundColor: "#f6f7fa" }}>
        <h3>Meet Our Leadership</h3>
        <p>We pride ourselves on personal service that goes beyond a computer screen or phone call. Meet the executives behind MSM’s visionary approach. </p>

        <ul className="ctr-roster-component">
          <li className="ctr-member">
            <h5 className="name">
              Matt Haberman
            </h5>
            <span className="title">
              Chief Executive Officer
            </span>
            <span className="description">
              Leading MSM’s radically<br /> transparent strategy
            </span>
            <img src={Portrait4} alt="Matt Haberman" />
          </li>
          <li className="ctr-member">
            <h5 className="name">
              Charles Caporale
            </h5>
            <span className="title">
              Chief Financial Officer
            </span>
            <span className="description">
              Spearheading financial growth <br />  and sustainability
            </span>
            <img src={Portrait1} alt="Charles Caporale" />
          </li>
          <li className="ctr-member">
            <h5 className="name">
              Michele Godges
            </h5>
            <span className="title">
              VP Revenue Cycle Operations
            </span>
            <span className="description">
              Driving revenue cycle growth <br />  and optimization
            </span>
            <img src={Portrait10} alt="Michelle Godges" />
          </li>
          <li className="ctr-member">
            <h5 className="name">
              Jessica York
            </h5>
            <span className="title">
              VP People and Communications
            </span>
            <span className="description">
              Leading MSM’s processes and  <br /> world-class team
            </span>
            <img src={Portrait3} alt="Jessica York" />
          </li>
          <li className="ctr-member">
            <h5 className="name">
              Mark Quenan
            </h5>
            <span className="title">
              VP Practice Management Services
            </span>
            <span className="description">
              Realizing practice's greatest <br /> operational potential
            </span>
            <img src={Portrait9} alt="Matt Haberman" />
          </li>
          <li className="ctr-member">
            <h5 className="name">
              Nic Beas
            </h5>
            <span className="title">
              Director, Client Services
            </span>
            <span className="description">
              Cultivating amazing <br /> relationships
            </span>
            <img src={Portrait6} alt="Nic Beas" />
          </li>
          <li className="ctr-member">
            <h5 className="name">
              Pam Pinkstaff
            </h5>
            <span className="title">
              Director, Contracting and Credentialing
            </span>
            <span className="description">
              Negotiating the right rates with <br /> the right plans
            </span>
            <img src={Portrait5} alt="Matt Haberman" />
          </li>

          <li className="ctr-member">
            <h5 className="name">
              Curt Zimmer
            </h5>
            <span className="title">
              Director, Finance
            </span>
            <span className="description">
              Organizing and optimizing <br /> finances
            </span>
            <img src={Portrait2} alt="Matt Haberman" />
          </li>
          <li className="ctr-member">
            <h5 className="name">
              Jeff Brady
            </h5>
            <span className="title">
              Director, Revenue Cycle Analysis
            </span>
            <span className="description">
              Fine-tuning your revenue <br /> cycle processes
            </span>
            <img src={Portrait8} alt="Matt Haberman" />
          </li>
        </ul>
      </section>

    </Layout>

  </React.Fragment >

)

export default RevCycle;